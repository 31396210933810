import { Box, BoxProps, HStack, Image } from '@chakra-ui/react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { BaseButton, BaseNav, RouteAuthenticationRequirement, useAuth, useNavItemStore } from '@cksoftware/react-base';
import { ApplicationRoutes } from '../../constants/appRoutes';
import logo from '../../assets/mopspot-logo.png';
import { AppRoles } from '../../constants/appRoles';
import { MopspotUser } from '../../globalModels/user/mopspotUser';

type NarrowLayoutProps = {
  containerProps?: BoxProps;
};

export const Layout = (props: NarrowLayoutProps) => {
  const nav = useNavigate();
  const routeNav = useNavItemStore((state) => {
    return { items: state.getDefault(), get: state.get };
  });

  const profileItems: BaseNav.NavItem = useMemo(() => {
    const subItems: BaseNav.NavItem[] = [
      routeNav.get(ApplicationRoutes.account.myProfile),
      routeNav.get(ApplicationRoutes.account.logout)
    ];
    return {
      text: 'Profile',
      subItems: subItems,
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      // @ts-ignore
      allowedRoles: []
    };
  }, []);

  const auth = useAuth<MopspotUser>();

  return (
    <Box as='section' width='100vw' height='100vh' overflowY='auto'>
      <BaseNav.Header
        navItems={routeNav.items}
        profileItems={profileItems}
        avatarName={`${auth.user?.FirstName} ${auth.user?.LastName}`}
        barColor={'secondary.500'}
        logo={
          <HStack>
            <Image paddingTop={'4px'} height={['35px']} src={logo} alt='logo' />
          </HStack>
        }>
        {auth.isAuthenticated && auth.isInRole([AppRoles.TeamMember]) && (
          <Box marginLeft={'auto'}>
            <BaseButton size={'xl'} onClick={() => nav(ApplicationRoutes.cleaning.startCleaning)}>
              Start Cleaning
            </BaseButton>
          </Box>
        )}
      </BaseNav.Header>

      <Box
        w={['100%', '100%', '100%', '90%']}
        margin={'auto'}
        paddingLeft={'5px'}
        paddingRight={'5px'}
        paddingTop={'10px'}
        {...props.containerProps}>
        <Outlet />
      </Box>
    </Box>
  );
};
