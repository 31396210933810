import { ApplicationRoutes } from '../constants/appRoutes';
import { RouteAuthenticationRequirement, RouteItem } from '@cksoftware/react-base';
import { Layout } from '../components/layout/layout';

export const SchedulingRoutes: RouteItem = {
  text: 'Schedule',
  allowedRoles: [],
  layout: <Layout containerProps={{ w: '100%' }} />,
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  path: ApplicationRoutes.scheduling.calendar,
  async lazy() {
    const component = await import('../features/scheduling/Calendar/Calendar');
    return { Component: component.Calendar };
  }
};
