import { ApplicationRoutes } from '../constants/appRoutes';
import { RouteAuthenticationRequirement, RouteItem } from '@cksoftware/react-base';
import { AppRoles } from '../constants/appRoles';
import { Layout } from '../components/layout/layout';

export const CrmRoutes: RouteItem = {
  text: 'CRM',
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [AppRoles.Admin],
  layout: <Layout />,
  subItems: [
    {
      text: 'Clients',
      path: ApplicationRoutes.crm.clients,
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [AppRoles.Admin],
      async lazy() {
        const component = await import('../features/crm/clients/ManageClientsPage');
        return { Component: component.ManageClientsPage };
      }
    },
    {
      text: 'Properties',
      path: ApplicationRoutes.crm.properties,
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [AppRoles.Admin],
      async lazy() {
        const component = await import('../features/crm/properties/ManagePropertiesPage');
        return { Component: component.ManagePropertiesPage };
      }
    }
  ]
};
