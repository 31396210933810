import { ApplicationRoutes } from '../constants/appRoutes';
import { RouteAuthenticationRequirement, RouteItem } from '@cksoftware/react-base';
import { AppRoles } from '../constants/appRoles';
import { Layout } from '../components/layout/layout';

export const CleaningRoutes: RouteItem = {
  text: 'Cleaning',
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [AppRoles.CleaningAdmin, AppRoles.Admin, AppRoles.TeamMember],
  layout: <Layout />,
  subItems: [
    {
      text: 'Start Cleaning',
      allowedRoles: [AppRoles.TeamMember],
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.cleaning.startCleaning,
      async lazy() {
        const component = await import('../features/cleaning/dashboard/StartCleaning');
        return { Component: component.StartCleaning };
      }
    },
    {
      allowedRoles: [AppRoles.TeamMember],
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: `/cleaning/cleaning-checklist/:type/:id`,
      async lazy() {
        const component = await import('../features/cleaning/checklist/CleaningChecklist');
        return { Component: component.CleaningChecklist };
      }
    },
    {
      text: 'Training Videos',
      allowedRoles: [AppRoles.TeamMember],
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.training.list,
      async lazy() {
        const component = await import('../features/training/view/TrainingVideos');
        return { Component: component.TrainingVideos };
      }
    },
    {
      allowedRoles: [AppRoles.TeamMember],
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.training.view,
      async lazy() {
        const component = await import('../features/training/view/TrainingVideoPlayer');
        return { Component: component.TrainingVideoPlayer };
      }
    }
  ]
};
