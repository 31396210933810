export class Constants {
  public static authPrefix = 'auth';
}

export const AppRoles = {
  Admin: 'Admin',
  CleaningAdmin: 'Cleaning Admin',
  UserAdmin: 'UserAdmin',
  TeamMember: 'Team Member',
  Helper: 'Helper',
  Inspector: 'Inspector',
  Manager: 'Manager'
};
