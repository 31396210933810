import {
  BaseCard,
  BaseNav,
  buildNavItemFromRoute,
  buildRoute,
  RouteError,
  useNavItemStore
} from '@cksoftware/react-base';
import { RouteObject } from 'react-router-dom';
import { Layout } from '../components/layout/layout';
import {
  AccountManagementItems,
  CustomerItems,
  LoginRouteItem,
  LogoutRouteItem,
  MyAccountRouteItem
} from './AccountRoutes';
import { AdminRoutes } from './AdminRoutes';
import { CleaningAdminRoutes } from './CleaningAdminRoutes';
import { CleaningRoutes } from './CleaningRoutes';
import { SchedulingRoutes } from './SchedulingRoutes';
import { CrmRoutes } from './CrmRoutes';

const AuthenticatedRouteItems = [
  AccountManagementItems,
  MyAccountRouteItem,
  LogoutRouteItem,
  CleaningRoutes,
  CleaningAdminRoutes,
  SchedulingRoutes,
  CrmRoutes,
  AdminRoutes
];
const UnauthenticatedRouteItems = [LoginRouteItem, AccountManagementItems, CustomerItems];

export function useNavWithRouting() {
  const setNavItemStore = useNavItemStore((state) => state.set);
  const routes = new Array<RouteObject>();
  const nav: BaseNav.NavItem[] = [];

  routes.push({
    path: '/',
    element: <Layout />,
    errorElement: <RouteError />,
    children: [
      {
        path: '/',
        element: <BaseCard header={'Welcome to the MopSpot Hub.'}></BaseCard>,
        errorElement: <RouteError />
      }
    ]
  });

  AuthenticatedRouteItems.forEach((value) => {
    routes.push(buildRoute(value));
    const navItem = buildNavItemFromRoute(value);
    if (navItem) {
      nav.push(navItem);
    }
  });

  UnauthenticatedRouteItems.forEach((value) => {
    routes.push(buildRoute(value));
    const navItem = buildNavItemFromRoute(value);
    if (navItem) {
      nav.push(navItem);
    }
  });

  setNavItemStore(nav);

  return routes;
}
