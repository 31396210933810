import { ApplicationRoutes } from '../constants/appRoutes';
import { RouteAuthenticationRequirement, RouteItem } from '@cksoftware/react-base';
import { Layout } from '../components/layout/layout';
import { AppRoles } from '../constants/appRoles';

export const AdminRoutes: RouteItem = {
  text: 'Admin',
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [AppRoles.UserAdmin, AppRoles.Admin],
  layout: <Layout />,
  subItems: [
    {
      text: 'Manage Users',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.admin.users,
      allowedRoles: [AppRoles.UserAdmin],
      async lazy() {
        const component = await import('../features/userAdmin/ManageUsers');
        return { Component: component.ManageUsers };
      }
    }
  ]
};
