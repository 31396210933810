import { defineSlotRecipe } from '@chakra-ui/react';
import { accordionAnatomy } from '@chakra-ui/react/anatomy';

export const accordionSlotRecipe = defineSlotRecipe({
  className: 'chakra-accordion',
  slots: accordionAnatomy.keys(),
  variants: {
    variant: {
      checklist: {
        itemTrigger: {
          padding: '10px',
          borderRadius: '2px',
          border: '1px dashed #ddd',
          _open: {
            backgroundColor: 'secondary.500',
            color: 'white',
            border: 'none'
          }
        },
        itemIndicator: {
          _open: {
            color: 'white'
          }
        },
        itemContent: {
          padding: '0px 10px',
          bg: 'secondary.100'
        }
      },
      outline: {
        item: {
          borderBottomWidth: '1px'
        }
      },

      subtle: {
        itemTrigger: {
          px: 'var(--accordion-padding-x)'
        },
        itemContent: {
          px: 'var(--accordion-padding-x)'
        },
        item: {
          borderRadius: 'var(--accordion-radius)',
          _open: {
            bg: 'colorPalette.subtle'
          }
        }
      },

      enclosed: {
        root: {
          borderWidth: '1px',
          borderRadius: 'var(--accordion-radius)',
          divideY: '1px',
          overflow: 'hidden'
        },
        itemTrigger: {
          px: 'var(--accordion-padding-x)'
        },
        itemContent: {
          px: 'var(--accordion-padding-x)'
        },
        item: {
          _open: {
            bg: 'bg.subtle'
          }
        }
      },

      plain: {}
    },

    size: {
      sm: {
        root: {
          '--accordion-padding-x': 'spacing.3',
          '--accordion-padding-y': 'spacing.2'
        },
        itemTrigger: {
          textStyle: 'sm',
          py: 'var(--accordion-padding-y)'
        }
      },
      md: {
        root: {
          '--accordion-padding-x': 'spacing.4',
          '--accordion-padding-y': 'spacing.2'
        },
        itemTrigger: {
          textStyle: 'md',
          py: 'var(--accordion-padding-y)'
        }
      },
      lg: {
        root: {
          '--accordion-padding-x': 'spacing.4.5',
          '--accordion-padding-y': 'spacing.2.5'
        },
        itemTrigger: {
          textStyle: 'lg',
          py: 'var(--accordion-padding-y)'
        }
      }
    }
  },

  defaultVariants: {
    size: 'md',
    variant: 'outline'
  }
});
