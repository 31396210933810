import { defineSlotRecipe } from '@chakra-ui/react';
import { segmentGroupAnatomy } from '@chakra-ui/react/anatomy';

export const segmentGroupSlotRecipe = defineSlotRecipe({
  className: 'chakra-segment-group',
  slots: segmentGroupAnatomy.keys(),

  variants: {
    size: {
      xs: {
        root: {
          height: '6'
        },
        item: {
          textStyle: 'xs',
          px: '3',
          gap: '1'
        }
      },
      sm: {
        root: {
          height: '8'
        },
        item: {
          textStyle: 'sm',
          px: '4',
          gap: '2'
        }
      },
      md: {
        root: {
          height: '10'
        },
        item: {
          textStyle: 'sm',
          px: '4',
          gap: '2'
        }
      },
      lg: {
        root: {
          height: '10'
        },
        item: {
          textStyle: 'md',
          px: '5',
          gap: '3'
        }
      },
      xl: {
        root: {
          height: '45px'
        },
        item: {
          textStyle: 'lg',

          p: '15px 50px',
          gap: '5'
        }
      }
    },
    variant: {
      checklist: {
        indicator: {
          shadow: 'sm',
          pos: 'absolute',
          bg: 'secondary.500',
          color: 'white',
          width: 'var(--width)',
          height: 'var(--height)',
          top: 'var(--top)',
          left: 'var(--left)',
          zIndex: -1,
          borderRadius: 'var(--segment-radius)'
        },

        itemText: {
          color: 'black',
          _checked: {
            color: 'white'
          }
        }
      }
    }
  },

  defaultVariants: {
    size: 'md'
  }
});
