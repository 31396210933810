import { createSystem, defaultConfig, defineConfig, mergeConfigs } from '@chakra-ui/react';
import { buildBaseTheme } from '@cksoftware/react-base';
import { accordionSlotRecipe } from './components/accordionSlotRecipe';
import { segmentGroupSlotRecipe } from './components/segmentedControlRecipe';

const baseTheme = buildBaseTheme({
  secondaryColor: '#c94d9d',
  primaryColor: '#73C7A7',
  tertiaryColor: '#5AB7E8',
  buttons: {
    borderRadius: 8
  }
});

const mopspotTheme = defineConfig({
  globalCss: {
    body: {
      backgroundColor: 'gray.50'
    }
  },
  theme: {
    slotRecipes: {
      accordion: accordionSlotRecipe,
      segmentGroup: segmentGroupSlotRecipe
    }
  }
});

const merged = mergeConfigs(defaultConfig, baseTheme, mopspotTheme);
export default createSystem(merged);
