import { ApplicationRoutes } from '../constants/appRoutes';
import { RouteAuthenticationRequirement, RouteItem } from '@cksoftware/react-base';
import { AppRoles } from '../constants/appRoles';
import { Layout } from '../components/layout/layout';

export const CleaningAdminRoutes: RouteItem = {
  text: 'Cleaning Admin',
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [AppRoles.CleaningAdmin, AppRoles.Admin],
  layout: <Layout />,
  subItems: [
    {
      text: 'Checklists',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [AppRoles.CleaningAdmin],
      path: ApplicationRoutes.cleaningAdmin.checklists,
      async lazy() {
        const component = await import('../features/cleaningAdmin/checklists/ManageChecklists');
        return { Component: component.ManageChecklists };
      }
    },
    {
      text: 'Submitted Checklists',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [AppRoles.CleaningAdmin],
      path: ApplicationRoutes.cleaningAdmin.submittedChecklists,
      async lazy() {
        const component = await import('../features/cleaningAdmin/submittedChecklists/SubmittedChecklists');
        return { Component: component.SubmittedChecklists };
      }
    },
    {
      text: 'Cleaning Packages',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [AppRoles.CleaningAdmin],
      path: ApplicationRoutes.cleaningAdmin.cleaningPackages,
      async lazy() {
        const component = await import('../features/cleaningAdmin/cleaningPackages/ManageCleaningPackages');
        return { Component: component.ManageCleaningPackages };
      }
    },
    {
      text: 'Training Videos',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      allowedRoles: [AppRoles.CleaningAdmin],
      path: ApplicationRoutes.cleaningAdmin.training,
      async lazy() {
        const component = await import('../features/training/admin/TrainingVideoAdmin');
        return { Component: component.TrainingVideoAdmin };
      }
    }
  ]
};
