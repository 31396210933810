import { ChecklistSectionType } from '../features/cleaningAdmin/checklists/types';

export const ApplicationRoutes = {
  customer: {
    checklist: '/customer/checklist'
  },
  account: {
    myProfile: '/my-profile',
    login: '/login',
    logout: '/logout',
    resetPassword: '/reset-password',
    confirmEmail: '/confirm-email',
    forgotPassword: '/forgot-password'
  },
  admin: {
    users: '/admin/users'
  },
  crm: {
    clients: '/crm/clients',
    properties: '/crm/properties'
  },
  cleaningAdmin: {
    inventory: '/cleaning-admin/inventory',
    checklists: '/cleaning-admin/checklists',
    cleaningPackages: '/cleaning-admin/cleaning-packages',
    training: '/cleaning-admin/video-admin',
    submittedChecklists: '/cleaning-admin/submitted-checklists'
  },
  scheduling: {
    calendar: '/scheduling/calendar',
    alerts: '/scheduling/alerts'
  },
  training: {
    list: '/training',
    view: '/training/view'
  },
  cleaning: {
    startCleaning: '/cleaning/start-cleaning',
    cleaningChecklist: (cleaningId: number) =>
      `/cleaning/cleaning-checklist/${ChecklistSectionType.Cleaner}/${cleaningId}`,
    inspectionChecklist: (cleaningId: number) =>
      `/cleaning/cleaning-checklist/${ChecklistSectionType.Inspection}/${cleaningId}`,
    helperChecklist: (cleaningId: number) => `/cleaning/cleaning-checklist/${ChecklistSectionType.Helper}/${cleaningId}`
  }
};
